<template>
<div class="row">
    <div class="col-sm-12 col-md-7">
        <div class="dataTables_paginate paging_simple_numbers">
                <ul class="pagination">
                    <li class="paginate_button page-item previous disabled" id="example2_previous"><a
                            href="#" class="page-link">上頁</a></li>
                            
                    <li v-for="page in pages" :key="page" class="paginate_button page-item"
                        :class="{'active': page === this.CurrentPage }">

                        <a @click="moveToPage(page)"  class="page-link">{{page}}</a>
                    </li>

                    <li class="paginate_button page-item next" id="example2_next">
                        <a href="#" class="page-link">下頁</a>
                    </li>
                    
                </ul>
        </div>
    </div>
    <div class="col-sm-12 col-md-5">
            <div class="dataTables_info pull-right">符合筆數:{{filterTotal}} </div>
            <div class="dataTables_info pull-right">總筆數:{{total}} </div>
        </div>    
</div>

</template>
<script>
export default {
    name: 'Pagination',
    props: {
        total: {            // 總筆數
            required: true,
            type: Number,
            default: 0
        },
        filterTotal: {            // 篩選後符合筆數
            required: true,
            type: Number,
            default: 0
        },
        CurrentPage: {      // 當前頁數
            type: Number,
            default: 1
        },
        pageSize: {      // 當前頁數
            type: Number,
            default: 8
        },  
    },
    data(){
        return {
            pages : [1,2,3,4,5,6,7,8],
            totalPages: 0,
            isPageNumberError : false,
            pageSizes : [20, 50, 100]
        }
    },
    watch: {
        filterTotal : function() {
            
            let size = Math.round(this.filterTotal/this.pageSize);
            if(size > 10) size = 10;

            this.pages.length = 0;
            for(var i = 1; i < size; i++)
            {
                this.pages.push(i);
            }
            console.log("按鈕數目"+ this.pages.length);
        }
    },
    methods:{
        moveToPage( pageNum ){

            var pNum = parseInt(pageNum);

			//页数不合法则退出
			if (!pNum 
            //    || pNum > this.totalPage 
                || pNum < 1) {
				console.log('頁碼輸入異常！');
                this.isPageNumberError = true;
				return false;		
			}else{
                this.isPageNumberError = false;
            }

			//页数变化时的回调
            try {
                this.$emit('changeCallback', pNum);
    
            } catch (error) {
                console.log(error);   
            }
            
		}
    }    

}
</script>

