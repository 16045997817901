<template>
    {{caseby}}
</template>
<script>
export default {
    name : 'HeaderIcon',
    props: {
        caseby: {
            type: String,
            default: ""
        }

    }
}

</script>