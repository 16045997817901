<template>
	<layout-div>
		<div class="card">
			<div class="card-header">
				<div class="btn-group">
					<router-link to="/Revenue/create" class="btn btn-primary">新增</router-link>
					<button @click="showfilterForm()" class="btn btn-secondary">搜尋</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th v-for="header in headers" :key="header.id">
										<a href="#" @click="sort(header.id);">
											{{ header.label }}
											<HeaderIcon :caseby=header.icon />
										</a>
									</th>
									<th width="240px">管理</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in model" :key="item.yymm">
									<td>{{formateDate(item.update_date)}}</td>
									<td>{{item.yymm}}</td>
									<td>{{item.code}}</td>
									<td>{{item.stock_name}}</td>
									<td>{{item.class_id}}</td>
									<td>{{item.revenue}}</td>
									<td>{{item.revenue_last_month}}</td>
									<td>{{item.revenue_last_year}}</td>
									<td>{{item.revenue_change_month.toFixed(2)}}</td>
									<td>{{item.revenue_change_year.toFixed(2)}}</td>
									<td>{{item.accumulation}}</td>
									<td>{{item.accumulation_last_year}}</td>
									<td>{{item.accumulation_change_month.toFixed(2)}}</td>
									<td>{{item.note}}</td>

									<td>
										<router-link :to="`/Revenue/edit/${item.editKey}`"
											class="btn btn-outline-success mx-1">編輯</router-link>
										<button @click="handleDelete(item.delKey)"
											className="btn btn-outline-danger mx-1">
											刪除
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<pagination :total=this.total :filterTotal=this.filterTotal :CurrentPage=this.pagination.page
					@changeCallback="onPageChange" />

			</div>
		</div>
	</layout-div>
	<div class="modal fade" id="filterModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">搜尋</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="card">
						<form asp-action="Index" id="searchForm">
							<div class="card-body row">
					<div class="col-auto">
						<label class="control-label">資料年月</label>
						<input v-model="filter.yymm" id="yymm" class="form-control" />
					</div>
					<div class="col-auto">
						<label class="control-label">公司代號</label>
						<input v-model="filter.code" id="code" class="form-control" />
					</div>
					<div class="col-auto">
						<label class="control-label">公司名稱</label>
						<input v-model="filter.stock_name" id="stock_name" class="form-control" />
					</div>
					<div class="col-auto">
						<label class="control-label">產業別</label>
						<input v-model="filter.class_id" id="class_id" class="form-control" />
					</div>

							</div>
						</form>
					</div>
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" @click="reset()" class="btn btn-primary">清除</button>
					<button type="button" @click="fetchList()" class="btn btn-primary">開始搜尋</button>
					<button type="button" class="btn btn-default" data-dismiss="modal">關閉</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>

<script>
import baseList from '@/views/baseList.vue'
import { listRevenue, deleteRevenue } from '@/api/Revenue.js'
import moment from 'moment';
import $ from 'jquery'

export default {
	name: 'Revenuelist',
	extends: baseList,
	data() {
		return {
			filter: {
					yymm: "",
					code: "",
					stock_name: "",
					class_id: "",
			},
			pagination: {
				sortItem: "yymm",
				sortDir: "ASC",
				page: 1,
				pageSize: 50
			},
			headers: [
				{id: 'update_date', label: "出表日期", icon: ""},
				{id: 'yymm', label: "資料年月", icon: "▼"},
				{id: 'code', label: "公司代號", icon: ""},
				{id: 'stock_name', label: "公司名稱", icon: ""},
				{id: 'class_id', label: "產業別", icon: ""},
				{id: 'revenue', label: "當月營收", icon: ""},
				{id: 'revenue_last_month', label: "上月營收", icon: ""},
				{id: 'revenue_last_year', label: "去年當月營收", icon: ""},
				{id: 'revenue_change_month', label: "上月比較增減(%)", icon: ""},
				{id: 'revenue_change_year', label: "去年同月增減(%)", icon: ""},
				{id: 'accumulation', label: "當月累計營收", icon: ""},
				{id: 'accumulation_last_year', label: "去年累計營收", icon: ""},
				{id: 'accumulation_change_month', label: "累計營收前期比較增減(%)", icon: ""},
				{id: 'note', label: "備註", icon: ""},

			],
			model: [],
			total: 0,
			filterTotal: 0

		};
	},
	computed: {
		

	},
	methods: {
		reset() {
			this.filter.yymm = "";
			this.filter.code = "";
			this.filter.stock_name = "";
			this.filter.class_id = "";

		},
		getRequest() {
			var result =
			{
				"filter": this.filter,
				"pagination": this.pagination
			};

			return result;
		},
		fetchList() {

			listRevenue(this.getRequest()).then(rep => {

				let sc = rep.data;
				this.total = sc.total;
				this.filterTotal = sc.filterTotal;
				this.model = sc.items;

				$("#filterModal").modal('hide');
			});
		},
		deleteRecord(id) {
			return deleteRevenue(id);
		},
		showfilterForm() {
			$("#filterModal").modal('show');
		},
		formateDate(t)
		{
			return moment(t).format('yyyy-MM-DD');
		}
	},
};
</script>
